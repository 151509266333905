<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">List Dapil</h3>
        </div>
        <div class="panel-body p-5">
          <vue-good-table
            :search-options="{
              enabled: true,
              placeholder: 'Masukkan Nama Dapil',
            }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: true,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: '',
            }"
            :rows="rows"
            :columns="columns"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-white mr-2"
                >
                  <i v-if="isLoading" class="fa fa-sync fa-spin"></i>
                  <i v-else class="fa fa-sync"></i>
                </button>
                <button
                  type="button"
                  @click="showModal"
                  class="btn btn-primary mr-2"
                >
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Tambah Data</span>
                </button>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div>
                  <router-link
                    :to="'/master/dapil/detail/' + props.row.id_dapil"
                    class="btn btn-success btn-circle btn-icon m-r-5"
                  >
                    <i class="fa fa-search"></i>
                  </router-link>
                  <button
                    class="btn btn-warning btn-circle btn-icon m-r-5"
                    v-on:click="
                      editDapil(
                        props.row.id_dapil,
                        props.row.kode_dapil,
                        props.row.nama_dapil
                      )
                    "
                  >
                    <i class="fa fa-pencil-alt"></i>
                  </button>
                  <button
                    class="btn btn-danger btn-circle btn-icon"
                    v-on:click="confirmDelete(props.row.id_dapil)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title">Input Pendukung</h5>
        <button
          type="button"
          @click="closeModal"
          class="btn btn-danger btn-sm btn-icon btn-circle"
          data-dismiss="modal"
          aria-hidden="true"
        >
          ×
        </button>
      </template>
      <div>
        <div class="form-group">
          <label for="kode_dapil">Kode Dapil</label>
          <input
            v-model="form.kode_dapil"
            type="text"
            name="kode_dapil"
            id="kode_dapil"
            class="form-control"
            :class="{
              'is-invalid': formValidate.kode_dapil,
            }"
            placeholder="Kode Dapil, Contoh : D-001"
          />
        </div>
        <div class="form-group">
          <label for="nama_dapil">Nama Dapil</label>
          <input
            v-model="form.nama_dapil"
            type="text"
            name="nama_dapil"
            id="nama_dapil"
            class="form-control"
            :class="{
              'is-invalid': formValidate.nama_dapil,
            }"
            placeholder="Nama Dapil"
          />
        </div>
      </div>
      <template #modal-footer>
        <button
          @click="postData"
          class="btn btn-block btn-info"
          variant="primary"
        >
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          Simpan
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "Kode Dapil",
          field: "kode_dapil",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Nama Dapil",
          field: "nama_dapil",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      form: {
        id_dapil: "",
        kode_dapil: "",
        nama_dapil: "",
      },
      modalVisible: false,
      conditionMet: false,
      formValidate: [],
      link: "/v1/dapil/create/",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("/v1/dapil/", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.isLoading = true;
      const formData = new FormData();

      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      if (this.form.id_dapil) {
        this.link = "/v1/dapil/update/";
      }

      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.isLoading = false;
            this.formValidate = response.data.message;
          } else {
            this.isLoading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            this.modalVisible = false;
            this.conditionMet = true;
            setTimeout(() => {
              Swal.close();
              this.resetForm();
              this.fetchData();
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/dapil/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    editDapil(id, kode, nama) {
      this.form.id_dapil = id;
      this.form.kode_dapil = kode;
      this.form.nama_dapil = nama;
      this.modalVisible = true;
      this.conditionMet = false;
    },
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },

    resetForm() {
      this.form.kode_dapil = "";
      this.form.nama_dapil = "";
      this.formValidate = [];
    },
  },
};
</script>